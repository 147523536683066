<template>
  <div class="flex flex-col gap-s16">
    <h1 class="headline-small">{{$t('kyc.modal.upload_documents.title')}}</h1>
    <p class="body-text-large">{{$t('kyc.modal.upload_documents.description')}}</p>
    <SkeletonLoading v-show="isLoading" height="300px"/>
    <div v-show="!isLoading" style="min-width: 500px; min-height: 500px;" id="socureDiv"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { addSDK, createSocureWidget, removeSDK } from '@/utils/sdk';
import adaptAttemptLevelUpForEmit from '@/modules/user/utils/adapt-attempt-level-up-for-emit.js';

export default {
  name: 'KycDocuments',
  data: () => ({
    socurePlugin: null,
    socureHandler: null,
    socureScriptId: 'socure-script-dom',
  }),
  props: {
    processEnum: {
      type: Number,
      default: 1,
    },
    kycData: {
      type: Object,
      required: false,
    }
  },
  computed: {
    ...mapGetters('user', ['getKycLevelForAmplitude','getKycLevelId']),
    isLoading(){
      return !this.socureHandler;
    }
  },
  methods: {
    ...mapActions('user', [
      'postDocument'
    ]),
    async addSocureToLocalHandler() {
      const token = process.env.VUE_APP_SOCURE_KEY;

      if (this.socureHandler) {
        await this.socureHandler.unmount();
      }

      this.socureHandler = await createSocureWidget(token, this.socurePlugin);

      const socureConfig = {
        onSuccess: this.onSuccess,
        onError: this.showError,
        qrCodeNeeded: true
      };

      this.socureHandler.init(token, '#socureDiv', socureConfig);

      this.socureHandler.start(this.processEnum, {
        customerUserId: this.getKycLevelId
      });
    },
    async mountSocureWidget() {
      try {
        await addSDK(this.socureScriptId, process.env.VUE_APP_SOCURE_SCRIPT);
        this.socurePlugin = window.SocureInitializer;
        this.addSocureToLocalHandler();
      } catch(e) {
        this.showError(e);
      }
    },
    unmountSocureWidget(){
      removeSDK(`#${this.socureScriptId}`);
      removeSDK('#embedSocure');
      if (window.SocureInitializer) {
        window.SocureInitializer = null;
      }
    },
    async onSuccess(event) {
      if (event.status !== 'DOCUMENTS_UPLOADED') {
        return;
      }

      const response = await this.postDocument(event.documentUuid);

      const emitVariables = adaptAttemptLevelUpForEmit(response);

      this.$store.dispatch('events/track', {
        event: 'KYC_UPLOAD_DOCUMENTS_UPLOADED',
        variables: {
          ...emitVariables,
        }
      });

      this.showModal('KycStatus');
    }
  },
  mounted(){
    this.mountSocureWidget();
    this.$store.dispatch('events/track', {
      event: 'KYC_UPLOAD_DOCUMENTS_SELECTION_VIEWED',
      variables: {
        ...this.getKycLevelForAmplitude,
      },
    });
    this.$store.dispatch('events/track', {
      event: 'KYC_UPLOAD_SELFIE_VIEWED',
      variables: {
        ...this.getKycLevelForAmplitude,
      },
    });
    this.$store.dispatch('events/track', {
      event: 'KYC_UPLOAD_DOCUMENTS_VIEWED',
      variables: {
        ...this.getKycLevelForAmplitude,
      },
    });
  },
  destroyed(){
    this.unmountSocureWidget();
  }
};
</script>
